<template>

  <div class="card card-custom mb-3">
    <!--begin::Card body-->
    <div class="card-body p-5">
      <div class="d-flex align-items-center justify-content-between">
        <div class="d-flex align-items-center">
          <div class="symbol symbol-100 symbol-light mr-1 flex-shrink-0">
            <div class="symbol-label">
              <img
                :src="
                  challenge.icon
                    ? challenge.icon.uri
                    : 'assets/media/svg/misc/014-kickstarter.svg'
                "
                class="h-75 align-self-center"
                alt=""
              />
            </div>
          </div>
          <div class="ml-4">
            <router-link
              :to="{ name: 'challengeDetail', params: { id: challenge.id } }"
            >
              <span
                class="h6 text-dark font-weight-bolder text-hover-primary font-size-normal"
                >{{ challenge.name }}</span>
            </router-link>
            <div class="font-size-sm text-muted font-weight-bold mt-1">{{$t("PARTIALSPROCESSI.DRIVERAMBIENTE")}}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ChallengeCard",
  props: ["challenge", "index"],
};
</script>

<style scoped></style>
